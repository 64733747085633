
.mobile {
    display: block;
    width: 100%;
    margin-bottom: 16px;
}
.search {
    flex-grow: 99;
}

@media only screen and (min-width: 768px) {
    .mobile {
        display: none;
    }
    .search {
        flex-grow: 0;
    }

}
